import useTranslation from "next-translate/useTranslation";
import { BulletPoints, Grid, H5 } from "@boxt/design-system";

import type { Flows } from "@Collections/photoFlows";
import type { PhotoKey, PhotoPreviewT } from "@Components/pages/photos/PhotoUpload/types";
import { ReactComponent as BulletSVG } from "@Images/icons/bullet.svg";

import DropUpload from "../DropUpload";
import Preview from "../Preview";
import UploadFailed from "../UploadFailed";

import PhotoExamples from "./components/PhotoExamples/PhotoExamples";
import { RequirementsContainer, RequirementsList } from "./styles";

type Props = {
  flow: Flows;
  hasFailedUploading: boolean;
  isUploading: boolean;
  onFileSelection: (files: PhotoPreviewT[]) => void;
  onRemovePreview: () => void;
  onRetryUpload: () => void;
  photoKey: PhotoKey;
  previewPhotos: PhotoPreviewT[];
  i18nNamespace: string;
};

type Requirements = {
  key: string;
  item: string;
};

const PhotoDetailsAndUpload = ({
  flow,
  hasFailedUploading,
  isUploading,
  onFileSelection,
  onRemovePreview,
  onRetryUpload,
  photoKey,
  previewPhotos,
  i18nNamespace,
}: Props) => {
  const { t } = useTranslation(i18nNamespace);
  const requirements: Requirements[] =
    t(`${i18nNamespace}:${photoKey}.requirements`, {}, { returnObjects: true }) || [];
  const isLegacyPhoto = photoKey === "other";
  const isAdditionalPhoto = photoKey === "additional-photo";
  return (
    <>
      <Grid.Row as={RequirementsContainer}>
        <Grid.Col as={RequirementsList} lg={isLegacyPhoto ? 16 : 8}>
          <H5 bottom={4}>{t("what-we-would-like")}</H5>
          <BulletPoints icon={<BulletSVG />} items={requirements} />
        </Grid.Col>
        {!(isLegacyPhoto || isAdditionalPhoto) && <PhotoExamples i18nNamespace={i18nNamespace} photoKey={photoKey} />}
      </Grid.Row>
      {hasFailedUploading && <UploadFailed i18nNamespace={i18nNamespace} onRetryUpload={onRetryUpload} />}
      {!previewPhotos.length && !hasFailedUploading && (
        <Grid.Row>
          <Grid.Col>
            <DropUpload flow={flow} i18nNamespace={i18nNamespace} onFileSelection={onFileSelection} />
          </Grid.Col>
        </Grid.Row>
      )}
      {!!previewPhotos.length && (
        <Preview
          i18nNamespace={i18nNamespace}
          isUploading={isUploading}
          onRemovePreview={onRemovePreview}
          previewPhotos={previewPhotos}
        />
      )}
    </>
  );
};

export default PhotoDetailsAndUpload;
