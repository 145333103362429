import Link from "next/link";
import Trans from "next-translate/Trans";
import useTranslation from "next-translate/useTranslation";
import { Button, Grid, Paragraph, Spacing } from "@boxt/design-system";

import { Container, PhotosDoneSVG, Row, Title, Wrapper } from "./styles";

type Props = { url: string | undefined; i18nNamespace: string };

const Common = ({ url, i18nNamespace }: Props) => {
  const { t } = useTranslation(i18nNamespace);

  return (
    <Grid.Row as={Row}>
      <Grid.Col as={Container} lg={{ offset: 3, span: 10 }} md={{ offset: 1, span: 6 }}>
        <Wrapper>
          <Title align={{ md: "left", sm: "center" }} bottom={3}>
            {t("title")}
          </Title>
          <Trans
            components={{ p: <Paragraph align={{ lg: "left", sm: "center" }} bottom={6} />, s: <strong /> }}
            i18nKey={`${i18nNamespace}:text`}
          />
          {Boolean(url) && (
            <>
              <Link href={url as string} legacyBehavior passHref>
                <Button boxtTheme="jade" data-testid="cta-button" size="medium">
                  {t("button")}
                </Button>
              </Link>
              <Spacing mb={4} />
            </>
          )}
        </Wrapper>
        <PhotosDoneSVG />
      </Grid.Col>
    </Grid.Row>
  );
};

export default Common;
