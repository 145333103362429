"use client";

import styled, { keyframes } from "styled-components";
import { breakpoint, Button, H4, includeSpacing } from "@boxt/design-system";

import { ReactComponent as LoadingSVG } from "@Images/icons/loading.svg";

export const PageBackground = styled.div`
  overflow: hidden;
  padding-bottom: 4rem;
`;

export const Container = styled.div`
  ${includeSpacing({ mt: { lg: 6, md: 5, sm: 3 } })};
`;

export const PhotoCount = styled(H4)`
  ${includeSpacing({ mb: 1 })};
`;

export const PhotoActions = styled.div`
  display: flex;
  flex-direction: column;

  ${breakpoint("md")} {
    justify-content: space-between;
    flex-direction: row;
  }
`;

export const NextPhotoAction = styled.div`
  // Changing order of items isnt great for accessibility
  // but in this instance it works as you go from upload file
  // to button to upload
  order: 0;
  flex-shrink: 0;
  display: flex;
  flex-wrap: wrap;
  ${includeSpacing({ mb: { md: 0, sm: 1 } })};

  ${breakpoint("md")} {
    order: 1;
    margin-left: auto;
  }
`;

export const StyledButton = styled(Button)`
  ${includeSpacing({ mb: { md: 0, sm: 2 }, ml: { md: 1, sm: 0 } })};
`;

export const Icon = styled.div`
  width: 100%;
  height: 100%;
  ${includeSpacing({ mb: 1 })};

  svg {
    margin: 0 auto;
    display: block;
  }
`;

const iconRotate = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const LoadingIcon = styled(LoadingSVG)`
  animation: ${iconRotate} 1s linear infinite;
`;
