import type { PropsWithChildren } from "react";
import { useRouter } from "next/compat/router";
import useTranslation from "next-translate/useTranslation";
import { Grid, NavLabel, Spacing } from "@boxt/design-system";

import getPathAndSearchParamsFromRouter from "@Helpers/getPathAndSearchParamsFromRouter";
import { Flows } from "@Collections/photoFlows";
import { BOXT_CUSTOMER_URLS, PHOTO_URLS } from "@Collections/routes";
import useStepNav from "@Hooks/useStepNav";

import { Container, PageBackground } from "../styles";

export type Props = PropsWithChildren & {
  id: string;
  flow: Flows;
  isFinalStep?: boolean;
  isRedirectedFromApp?: boolean;
  shouldGoToUploadPhotosPageOnBack?: boolean;
  i18nNamespace: string;
};

const PageContainer = ({
  flow,
  children,
  id,
  isFinalStep = false,
  isRedirectedFromApp = false,
  shouldGoToUploadPhotosPageOnBack = false,
  i18nNamespace,
}: Props) => {
  const { t } = useTranslation(i18nNamespace);
  const router = useRouter();

  const hasNavBar =
    [Flows.repairs, Flows.orders, Flows.homecover, Flows.rental, Flows.callouts, Flows.solar].includes(flow) &&
    !isRedirectedFromApp;
  const navTheme = flow === Flows.rental ? "acidGreen" : undefined;

  const handleBack = () => {
    if (isFinalStep) {
      return router?.push(BOXT_CUSTOMER_URLS.HOME);
    }

    if (shouldGoToUploadPhotosPageOnBack) {
      const { searchParams } = getPathAndSearchParamsFromRouter(router);

      return router?.push(`${PHOTO_URLS.BASE}/${flow}/${id}/upload-photos${searchParams}`);
    }

    return router?.push(PHOTO_URLS[flow].replace(/\[(.*?)\]/, id));
  };

  const { navLabelProps } = useStepNav();

  return (
    <PageBackground>
      {hasNavBar && (
        <Grid.Row>
          <Grid.Col>
            <NavLabel
              {...navLabelProps}
              boxtTheme={navTheme}
              isFinalStep={isFinalStep}
              onBack={handleBack}
              {...(!isFinalStep && { label: t("nav-label") })}
            />
          </Grid.Col>
        </Grid.Row>
      )}
      <Grid.Row>
        <Grid.Col>
          <Spacing mt={hasNavBar ? 6 : 3} />
        </Grid.Col>
      </Grid.Row>
      <Grid forwardedAs={Container}>{children}</Grid>
    </PageBackground>
  );
};

export default PageContainer;
